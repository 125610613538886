//  Bosnian
export default{
    form: {
        startSurveyButton: "Započnimo",
        nextButton: "Dalje",
        submitAnswerButton: "Pošalji odgovor",
        continueButton: "Nastavi",
        finishButton: "Završi",
        headerPercentageCompletion: 'završeno',
        headerQuestionsCompleted: 'pitanja',
        headerDefaultErrorMessage: 'Ups! Nešto je pošlo po zlu.',
        brandingPoweredBy: 'Pokreće',
        brandingPromoWhySurvey: 'Zašto raditi anketu kada možete koristiti <b>{product}</b>?',
        brandingPromoGetStarted: 'Započnite danas!',
        submissionPending: "Vaš voiceform se šalje. Nemojte zatvarati prozor preglednika.",
        submissionCanCloseWindow: "Vaš voiceform je uspješno poslan. Sada možete zatvoriti prozor 👍",
        mediaAttachmentOverlayCloseButton: "Zatvori",
        expiredFormHeader: "Hvala što ste navratili!",
        expiredFormMessage: "Izgleda da je ova anketa istekla. Molimo kontaktirajte kreatora voiceforma kako biste ga obavijestili.",
        notPublishedFormHeader: "Voiceform nije objavljen!",
        notPublishedFormMessage: "Izgleda da ova anketa nije objavljena. Molimo kontaktirajte kreatora voiceforma kako biste ga obavijestili.",
        notPublishedFormRetryButton: "Pokušaj ponovo",
        errorFormHeader: "Oops!",
        errorFormMessage: "Nešto je možda pošlo po zlu. Molimo pokušajte kasnije.",
        errorFormRetryButton: "Pokušaj ponovo",
        emptyFormHeader: "Hvala što ste navratili!",
        emptyFormMessage: "Ups! Čini se da je ovaj formular trenutno prazan, bez pitanja ili polja za popunjavanje. Izvinjavamo se na neugodnostima. Molimo kontaktirajte kreatora ovog formulara kako biste ga obavijestili o ovom problemu. Hvala na razumijevanju i saradnji!",

        restoreFormHeader: "Izgleda da imate započeti formular!",
        restoreFormMessage: "Želite li nastaviti od mjesta gdje ste stali?",
        restoreFormButton: "Nastavi formular",
        restoreFormButtonNew: "Počni iznova",

        audioPermissionHeader: "Želite li odgovoriti glasom?",
        audioPermissionMessage: "Govorenje će omogućiti brz i jednostavan odgovor.",
        audioPermissionAcceptButton: "Da, želim",
        audioPermissionDenyButton: "Ne, hvala",
        audioPermissionErrorHeader: "Mikrofon nije dostupan",
        audioPermissionErrorMessage: "Provjerite dozvole preglednika da biste omogućili pristup mikrofonu.",
        audioPermissionErrorButton: "U redu",
        audioRecorderFailedUpload: "Neuspješno učitavanje snimka",
        silenceDetectorError: "Ne čujemo vas. Provjerite postavke mikrofona. Savjet: Možda koristite vanjski mikrofon.",
        audioRecorderButtonRecord: "Započni snimanje",
        audioRecorderButtonRecordMore: "Snimaj više",
        voiceResponseSelectionMessage: "Odaberite kako želite odgovoriti...",
        voiceResponseSelectionOrDivider: "ili",
        voiceResponseTextInputPlaceholder: "Upišite odgovor",
        voiceResponseTextInputCharacterCounterMinMessage: "Unesite najmanje {min} znakova",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} znakova (najmanje {min} znakova)",
        voiceResponseTextInputCharacterValidationMessage: "{count} znakova (najmanje {min} znakova)",

        // checkbox
        checkboxValidationTooFew: "Morate odabrati najmanje {min} opciju(e)",
        checkboxValidationTooMany: "Morate odabrati najviše {max} opciju(e)",
        checkboxNoneOfTheAboveOption: "Nijedno od navedenog",
        checkboxInvalidAnswer: "Nevažeći odgovor.",

        // datepicker
        datePickerPlaceholder: "Odaberite datum",

        // dropdown
        // email
        emailLabel: "E-mail",
        emailInvalid: "Nevažeći e-mail.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Snimanje videa",
        fileUploadPluginScreenCast: "Snimanje ekrana",
        fileUploadVideoImportFilesDefault: 'Snimite ili učitajte video putem:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Odaberite opciju za snimanje videa:',
        fileUploadVideoImportFilesNoCamera: 'Kliknite dugme za snimanje video ekrana',
        fileUploadVideoImportFilesNoScreenCast: 'Kliknite dugme za snimanje videa',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Nema dostupnih opcija za snimanje videa',

        // matrix
        matrixValidationMessage: "Odgovor je važeći.",
        matrixRow: "Red",
        matrixColumn: "Kolona",
        matrixRowRequired: "Red {rowTitle} je obavezan.",
        matrixRadioGroup: "Grupa dugmadi",
        matrixCheckboxGroup: "Grupa s kvačicama",
        matrixGroupRequired: "Za red {rowTitle}. {type} {groupTitle} je obavezno.",
        matrixColumnRequired: "Za red {rowTitle}. Kolona {columnTitle} je obavezna.",
        matrixColumnInvalid: "Za red {rowTitle}. Kolona {columnTitle} nije važeća.",
        matrixRequired: "Obavezno.",
        matrixNumericMustBeNumber: "Mora biti broj.",
        matrixNumericMustBeGreaterThenMin: "Mora biti veće od {min}.",
        matrixNumericMustBeLessThenMax: "Mora biti manje od {max}.",
        matrixNumericMustBeInteger: "Mora biti cijeli broj.",
        matrixNumericInvalidNumber: "Nevažeći broj.",

        // name
        nameLabel: "Ime",
        nameInvalid: "Navedite ime i prezime.",

        // nps
        npsNotLikely: "Vrlo mala vjerovatnoća",
        npsExtremelyLikely: "Vrlo velika vjerovatnoća",

        // numeric input
        numericInputRequired: "Obavezno.",
        numericInputMustBeNumber: "Mora biti broj.",
        numericInputMustBeGreaterThenMin: "Mora biti veće od {min}.",
        numericInputMustBeLessThenMax: "Mora biti manje od {max}.",
        numericInputMustBeInteger: "Mora biti cijeli broj.",
        numericInputInvalidNumber: "Nevažeći broj.",
        numericInputPlaceholder: "Unesite broj",

        // phone
        phoneInvalid: "Broj telefona nije važeći.",
        phoneCountrySelectorLabel: 'Pozivni broj države',
        phoneCountrySelectorError: 'Odaberite državu',
        phoneNumberLabel: 'Broj telefona',
        phoneExample: 'Primjer:',

        // boolean
        booleanYesLabel: "Da",
        booleanNoLabel: "Ne",

        //questionStep
        questionStepAudioQuestionLabel: "Audio Pitanje",

        // errors
        invalidPhoneNumber: "{phone} nije važeći broj telefona.",
        invalidEmail: "{email} nije važeća e-mail adresa.",
        questionIsRequired: "Pitanje je obavezno.",
        answerIsNotValid: "Odgovor nije važeći.",
        unfinishedProbingDialogError: "Završite dijalog.",
        cannotResumePartialResponse: "Nije moguće nastaviti djelimičan odgovor.",
        failedToSubmitForm: "Neuspješno slanje forme. Provjerite svoju internet vezu i pokušajte ponovo.",

        //language picker
        searchLanguage: "Pretraži jezik",
    }
}
